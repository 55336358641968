import React from 'react';
import Section from '../sections/Section';
import './HeaderSection.css';

const headshotImageSrc = `${process.env.PUBLIC_URL}/images/Dan Prince - LinkedIn.jpg`;
const headshotImageAltTxt = 'Dan Prince';
const msBadgeImageSrc = `${process.env.PUBLIC_URL}/images/microsoft-certified-associate-badge.svg`;
const msBadgeImageAltTxt = 'Microsoft Certification Associate Badge';

const TITLE = '';
const CERTIFICATION_LINK = 'https://learn.microsoft.com/en-us/users/danielprince-7660/credentials/certification/azure-ai-engineer?tab=credentials-tab';

const getFormattedDate = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  const monthNames = ["January", "February", "March", "April", "May", "June",
                      "July", "August", "September", "October", "November", "December"];
  const mm = monthNames[today.getMonth()]; // Get the month name
  const dd = String(today.getDate()).padStart(2, '0'); // Pad the date with leading zeros if necessary
  return `${mm} ${dd} ${yyyy}`;
};

const handleDownload = (fileType) => {
  const link = document.createElement('a');
  const date = getFormattedDate();
  const fileName = `Dan Prince resume ${date}.${fileType}`;
  link.href = `/${fileType === 'docx' ? 'resume.docx' : 'resume.pdf'}`;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const CONTENT = (
  <header>
    <div className="profile-image">
      <img src={headshotImageSrc} alt={headshotImageAltTxt} />
    </div>
    <div className="header-content">
      <h1>Dan Prince</h1>
      <h2>Modern Software Developer</h2>
      <h3>BSCS & MPM</h3>
      <div className="certification">
        <a href={CERTIFICATION_LINK} target="_blank" rel="noopener noreferrer">
          <img className="certification-badge" src={msBadgeImageSrc} alt={msBadgeImageAltTxt} />
        </a>
        <p className="certification-title">
          <a href={CERTIFICATION_LINK} target="_blank" rel="noopener noreferrer">
            Microsoft Certified: Azure AI Engineer Associate
          </a>
        </p>
      </div>
    </div>
    <div className="resume-downloads">
      <a href="#" onClick={() => handleDownload('docx')}>
        <i className="fas fa-file-word resume-icon" title="Download Resume (DOCX)"></i>
      </a>
      <a href="#" onClick={() => handleDownload('pdf')}>
        <i className="fas fa-file-pdf resume-icon" title="Download Resume (PDF)"></i>
      </a>
    </div>
  </header>
);

class HeaderSection extends Section {
  render() {
    return (
      <Section title={TITLE}>
        {CONTENT}
      </Section>
    );
  }
}

export default HeaderSection;
