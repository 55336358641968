import React from 'react';
import Section from '../sections/Section';
const TITLE = 'Hobbies';
const CONTENT = (
  <ul>
    <li>Technology</li>
    <li>Fishing</li>
    <li>Chess</li>
    <li>Tools</li>
  </ul>
);

class HobbiesSection extends Section {
  render() {
    return (
      <Section title={TITLE}>
        {CONTENT}
      </Section>
    );
  }
}

export default HobbiesSection;
