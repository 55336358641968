import React, { useState, useEffect, useRef } from 'react';
import './PrivacyPolicy.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPrint } from '@fortawesome/free-solid-svg-icons';

const PrivacyPolicy = () => {
    const [visible, setVisible] = useState(false);
    const printRef = useRef();

    useEffect(() => {
        if (visible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [visible]);

    const showPolicy = () => setVisible(true);
    const hidePolicy = () => setVisible(false);

    const printPolicy = () => {
        const printContents = printRef.current.cloneNode(true);
        const headerContainer = printContents.querySelectorAll('.header-buttons');
        headerContainer.forEach(hc => hc.remove());

        const printWindow = window.open('https://danprince.info', 'Privacy Policy', 'height=600,width=800');

        const printHtml = `
      <html>
        <head>
          <title>Privacy Policy</title>
           <style>
        @media print {
            body * {
                visibility: visible;
            }

            .privacy-policy-content {
                visibility: visible;
            }

            .privacy-policy-content {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: auto;
                overflow: visible;
            }

            .privacy-policy-header {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                background: white;
                z-index: 1000;
                padding: 5px 15px 0px 0px;
                margin-bottom: 5px 5px 0px 0px;
            }

            .privacy-policy-scrollable {
                margin-top: 60px;
            }
        }
        </style>
        <script type="text/javascript">
        function printMyself() {
            window.print();
            window.close();
        }
        window.onload = printMyself;
        </script>
        </head>
        <body>
          ${printContents.innerHTML}
        </body>
      </html>
    `;
        console.log(printHtml);

        printWindow.document.write(printHtml);
        printWindow.document.close();
    };

    return (
        <>
            <a href="policy:privacy" onClick={(e) => { e.preventDefault(); showPolicy(); }} className="privacy-policy-link">Privacy Policy</a>
            {visible && (
                <div className="privacy-policy-overlay">
                    <div className="privacy-policy-content" ref={printRef}>
                        <div className="privacy-policy-header">
                            <h1>Privacy Policy</h1>
                            <div className="header-buttons">
                                <button className="icon-button" onClick={printPolicy}>
                                    <FontAwesomeIcon icon={faPrint} />
                                </button>
                                <button className="icon-button" onClick={hidePolicy}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            </div>
                        </div>
                        <div className="privacy-policy-scrollable">
                            <div className="privacy-policy-inner">
                                <p>I value your privacy and am committed to protecting your information. This Privacy Policy explains how I collect, use, and safeguard your data when you visit my website.</p>

                                <h2>Data Collection</h2>
                                <p>I collect information from you when you visit my site, including:</p>
                                <ul>
                                    <li>IP addresses</li>
                                    <li>Browser type</li>
                                    <li>Operating system</li>
                                    <li>Referring site</li>
                                    <li>Pages visited</li>
                                    <li>Time spent on each page</li>
                                </ul>

                                <h2>Use of Google Analytics</h2>
                                <p>I use Google Analytics to collect information about how visitors use the site. Google Analytics collects data such as pages visited, time spent on each page, referring sites, and other information to help me improve the website. This information is collected anonymously and does not identify individual users.</p>

                                <h2>Cookies and Tracking Technologies</h2>
                                <p>The website uses cookies and similar tracking technologies to enhance your browsing experience and gather information about your usage patterns. You can set your browser to refuse cookies or alert you when cookies are being sent. However, some parts of the site may not function properly without cookies.</p>

                                <h2>Data Sharing and Disclosure</h2>
                                <p>I do NOT save, much less sell, trade, or otherwise transfer your personal information to outside parties, except as required by law or to protect the rights. I DO NOT share data with third-party service providers.</p>

                                <h2>Data Security</h2>
                                <p>I do NOT save your personal information.</p>

                                <h2>Changes to the Privacy Policy</h2>
                                <p>I may update this Privacy Policy from time to time, though it's unlikely. Any changes will be posted on this page, and I encourage you to review it periodically.</p>

                                <h2>Contact Information</h2>
                                <p>If you have any questions about this Privacy Policy, please contact me at:</p>
                                <p>Email: <a href='mailto:danp6481+privacypolicy@gmail.com'>danp6481@gmail.com</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PrivacyPolicy;
