import React from 'react';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Timestamp from './components/TimeStamp';
import LeftColumn from './components/columns/LeftColumn';
import RightColumn from './components/columns/RightColumn';
import WhoIAmSection from './components/sections/WhoIAmSection';
import ContactSection from './components/sections/ContactSection';
import HobbiesSection from './components/sections/HobbiesSection';
import EducationSection from './components/sections/EducationSection';
import ExperienceSection from './components/sections/ExperienceSection';
import HeaderSection from './components/sections/HeaderSection';
import PrivacyPolicy from './components/PrivacyPolicy'; // Import the PrivacyPolicy component

function App() {
  return (
    <div className="container">
      <HeaderSection />
      <div className="content">
        <LeftColumn>
          <WhoIAmSection />
          <ContactSection />
          <HobbiesSection />
          <EducationSection />
        </LeftColumn>
        <RightColumn>
          <ExperienceSection />
        </RightColumn>
      </div>
      <footer className="footer">
        &copy; {new Date().getFullYear()} Dan Prince. All rights reserved.
        <PrivacyPolicy />
        <Timestamp />
      </footer>
    </div>
  );
}

export default App;
