import React from 'react';
import Column from './Column';
import './RightColumn.css';

class RightColumn extends Column {
  render() {
    return (
      <Column className="right-column">
        {this.props.children}
      </Column>
    );
  }
}

export default RightColumn;
