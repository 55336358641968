import React from 'react';
import Section from '../sections/Section';
import IllumisoftExperience from '../experiences/IllumisoftExperience';
import EpiqExperience from '../experiences/EpiqExperience';
import CSCExperience from '../experiences/CSCExperience';

const TITLE = 'Experience';
const CONTENT = (
  <div>
    <IllumisoftExperience />
    <EpiqExperience />
    <CSCExperience />
  </div>
);

class ExperienceSection extends Section {
  render() {
    return (
      <Section title={TITLE}>
        {CONTENT}
      </Section>
    );
  }
}

export default ExperienceSection;
