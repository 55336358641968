import React, { Component } from "react";
import Experience from "./Experience";

const company = "illumisoft";
const jobTitle = "Owner, Lead Architect, CEO";
const dateFrom = "August 2014";
const dateTo = "March 2023";
const description = () => (`
illumisoft was a company that built custom software for various healthcare, 
logistics, and supply chain management clients. My role was to find out what 
they needed, put it into their broader business context, and develop a solution. 
I then managed the solution's architecture, set up CI/CD pipelines, and managed 
customer progress awareness and any changes to the requirements.
`);
const accomplishments = () => (`
At our peak, we had annual revenue of over 3 million, 12 people working with 
us, and completed over a dozen successful projects.
`);
const skills = () => (`
Azure, React, Node.js, C#, .NET, SQL Server, Agile, Scrum, Kanban
`);

class IllumisoftExperience extends Component {
    render() {
        return (
            <Experience
                company={company}
                jobTitle={jobTitle}
                dateFrom={dateFrom}
                dateTo={dateTo}
                description={description}
                accomplishments={accomplishments}
                skills={skills} />
        );
    }
}

export default IllumisoftExperience;