// ConsentHandler.js
import { useEffect } from 'react';

const ConsentHandler = () => {
  useEffect(() => {
    // Array of callbacks to be executed when consent changes
    const consentListeners = [];

    /**
     * Called from GTM template to set callback to be executed when user consent is provided.
     * @param {function} callback - Callback to execute on user consent
     */
    window.addConsentCallback = (callback) => {
      consentListeners.push(callback);
    };

    /**
     * Called when user grants/denies consent.
     * @param {Object} consent - Object containing user consent settings.
     */
    const onConsentChange = (consent) => {
      consentListeners.forEach((callback) => {
        callback(consent);
      });
    };

    // Example of adding a consent callback
    window.addConsentCallback((consent) => {
      console.log('User consent changed:', consent);
      // Handle consent changes here
    });

    // Example of how you might trigger the consent change manually for testing
    // In production, this would be triggered by your CMP or GTM
    setTimeout(() => {
      onConsentChange({ ad_storage: 'granted', analytics_storage: 'denied' });
    }, 5000);

    // Cleanup function to remove consent listeners if component is unmounted
    return () => {
      consentListeners.length = 0;
    };
  }, []);

  return null; // This component does not render anything
};

export default ConsentHandler;
