import React, { Component } from "react";
import Experience from "./Experience";

const company = "Epiq Systems";
const jobTitle = "Software Development Manager";
const dateFrom = "December 2013";
const dateTo = "June 2015";
const description = () => (`
At Epiq Systems, I was hired to lead a critical system transformation for 
software designed to support trustees of corporate bankruptcy cases. My 
mission was to complete a project that had previously been attempted twice. 
My role involved collaborating with stakeholders, gathering and understanding 
complex business requirements, reverse engineering an overly complex 
solution, and rearchitecting the system to eliminate vulnerabilities.
`);
const accomplishments = () => (` 
Under my leadership, the team delivered several high-quality updates that 
saved the company an estimated $100 million.`);
const skills = () => (` 
C#, .NET, SQL Server, Agile, Scrum
`);

class EpiqExperience extends Component {
    render() {
        return (
            <Experience
                company={company}
                jobTitle={jobTitle}
                dateFrom={dateFrom}
                dateTo={dateTo}
                description={description}
                accomplishments={accomplishments}
                skills={skills} />
        );
    }
}

export default EpiqExperience;