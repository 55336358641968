import React from 'react';
import Section from '../sections/Section';

const TITLE = "Who I Am";
const CONTENT = (
  <p>I am a seasoned technology professional with extensive experience managing various customers and projects. Leveraging consulting expertise gained from over a decade of custom software development, I excel in translating complex business cases into maintainable cloud solutions. From the beginning of my career, I have maintained a strong customer-centered approach and fostered happy, agile, meritocratic environments that work quickly and achieve remarkable results.</p>
);

class ProfessionalSummarySection extends Section {
  render() {
    return (
      <Section title={TITLE}>
        {CONTENT}
      </Section>
    );
  }
}

export default ProfessionalSummarySection;
