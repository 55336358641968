import React from 'react';
import Section from '../sections/Section';

const TITLE = 'Education';
const CONTENT = (
  <ul>
    <li><strong>Master of Project Management (MPM)</strong>, Keller Graduate School of Management, 2001</li>
    <li><strong>BS in Computer Science</strong>, Missouri State University, 1997 (Summa Cum Laude – 3.98 GPA)</li>
  </ul>
);

class EducationSection extends Section {
  render() {
    return (
      <Section title={TITLE}>
        {CONTENT}
      </Section>
    );
  }
}

export default EducationSection;
