import React from 'react';
import './Experience.css';

class Experience extends React.Component {
  render() {
    return (
      <div className="experience">
        <div className="experience-company">{this.props.company} - {this.props.jobTitle}</div>
        <div className="experience-date">{this.props.dateFrom} - {this.props.dateTo}</div>
        <div className="experience-description">{this.props.description()}
          <div className="experience-accomplishments">{this.props.accomplishments()}</div>
          <div className='experience-skills'>Primary Technologies: {this.props.skills()}</div>
        </div>
      </div>
    );
  }
}

export default Experience;
