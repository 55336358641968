import React from 'react';
import '../sections/Section.css';


class Section extends React.Component {
  render() {
    const { title, children } = this.props;

    return (
      <section className="section">
        <div className="title-div">
          <h3 className="section-title">
            {title}
          </h3>
        </div>
        <div className="section-content">
          {children}
        </div>
      </section>
    );
  }
}

export default Section;
