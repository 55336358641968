import React from 'react';
import Section from '../sections/Section';

const TITLE = 'Contact';
const CONTENT = (
  <div>
    <p><strong>Phone:</strong> <a href="tel:816-678-0874">816-678-0874</a></p>
    <p><strong>Website:</strong> <a href="https://danprince.info">danprince.info</a></p>
    <p><strong>Email:</strong> <a href="mailto:danp6481@gmail.com">danp6481@gmail.com</a></p>
  </div>
);

class ContactSection extends Section {
  render() {
    return (
      <Section title={TITLE}>
        {CONTENT}
      </Section>
    );
  }
}

export default ContactSection;
