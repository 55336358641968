import React from 'react';
import Column from './Column';
import './LeftColumn.css';

class LeftColumn extends Column {
  render() {
    return (
      <Column className="left-column">
        {this.props.children}
      </Column>
    );
  }
}

export default LeftColumn;
