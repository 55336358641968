import React, { Component } from "react";
import Experience from "./Experience";

const company = "CSC";
const jobTitle = "Solution Architect";
const dateFrom = "January 2011";
const dateTo = "June 2014";
const description = () => (`
My team at CSC was on contract to the Army. I served as Team Lead and then 
Solution Architect, where our mission was to implement a solution for documenting 
processes for the Army. My role involved collaborating with GS-13 stakeholders 
to understand requirements and manage expectations. I then created technical 
specifications and guided a cross-functional team to meet client expectations.
`);
const accomplishments = () => (`
My efforts resulted in the successful delivery of several key solutions that 
significantly contributed to client satisfaction and contract success. 
`);
const skills = () => (` 
Azure, React, Node.js, C#, .NET, SQL Server, Agile, Scrum, Kanban
`);

class CSCExperience extends Component {
    render() {
        return (
            <Experience
                company={company}
                jobTitle={jobTitle}
                dateFrom={dateFrom}
                dateTo={dateTo}
                description={description}
                accomplishments={accomplishments}
                skills={skills} />
        );
    }
}

export default CSCExperience;