import React, {Component} from 'react';
import './Column.css'

class Column extends Component{

  render() {
    const { className, children } = this.props;
    return (
      <div className={className}>
        {children}
      </div>
    )
  }  
}

export default Column;
